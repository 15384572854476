import React, { useRef, useState } from "react";
import gradients from "./gradients.json";
import Editor, { Monaco } from "@monaco-editor/react";
import { range } from "lodash";

function makeGradient(g: any) {
  const points = g.gradient.map((p: any) => `${p.color} ${p.pos}%`).join(", ");
  return `linear-gradient(${g.deg}deg, ${points})`;
}

function App() {
  const [selectedIndex, setIndex] = useState(-1);
  const [text, setText] = useState("");
  const editorRef = useRef<Monaco>(null);
  const iconSize = 64;
  const handleEditorDidMount = (editor: any, monaco: any) => {
    editorRef.current = editor;
  };
  const handleEditorChange = (value?: string) => {
    setText(value || "");
  };

  return (
    <div className="App">
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          flexDirection: 'column',
          alignItems: "center",
          height: "calc(100% - 200px)",
          width: "50%",
        }}
      >
        {[1, 3].map((n: number) => (
          <div
            style={{
              margin: 10,
              width: iconSize * n,
              height: iconSize * n,
              borderRadius: 10 * n,
              backgroundImage: text,
            }}
          >
            <img
              style={{ width: iconSize * n, height: iconSize * n }}
              src="/icon.png"
            ></img>
          </div>
        ))}
      </div>
      <div
        style={{
          position: "absolute",
          left: 0,
          bottom: 0,
          height: "200px",
          width: "50%",
        }}
      >
        <Editor
          height={150}
          defaultLanguage="css"
          value={text}
          onChange={handleEditorChange}
          onMount={handleEditorDidMount}
        />
      </div>
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          bottom: "30%",
          width: "50%",
        }}
      ></div>

      <div
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          bottom: 0,
          width: "50%",
          overflowY: "scroll",
        }}
      >
        {gradients.map((g, index) => (
          <a
            key={index}
            onClick={() => {
              setIndex(index);
              setText(makeGradient(g));
            }}
          >
            <div
              className="card"
              style={{
                outlineWidth: selectedIndex === index ? 2 : 0,
                outlineStyle: "solid",
                outlineColor: "var()",
                float: "left",
                margin: 10,
                width: 150,
                height: 200,
              }}
            >
              <div
                className="card-body"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: iconSize,
                    height: iconSize,
                    borderRadius: 10,
                    backgroundImage: makeGradient(gradients[index]),
                  }}
                >
                  <img
                    style={{ width: iconSize, height: iconSize }}
                    src="/icon.png"
                  ></img>
                </div>
              </div>
              <div className="card-footer" style={{}}>
                {g.name}[{index}]
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default App;
